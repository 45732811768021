import Joi from 'joi-browser';

// Register page schema
const registerSchema = Joi.object().keys({
  //first name must be string within 30 character limit
  first_name: Joi.string().alphanum().max(30).required().label('First name'),

  //last name must be string within 30 character limit
  last_name: Joi.string().alphanum().max(30).required().label('Last name'),

  // company code must be within 30 characters
  companyCode: Joi.string().alphanum().max(30).required().label('Company code'),

  // email must require with two minimun domain atoms
  email: Joi.string()
    .email({
      minDomainAtoms: 2
    })
    .required()
    .label('Email'),

  // mobile should be number
  mobile: Joi.number().required().label('Mobile number'),

  // passowrd required
  password: Joi.string().max(30).required().label('Password')
});

// Profile Page schema
const userProfileSchema = Joi.object().keys({
  //first name must be string within 30 character limit
  firstName: Joi.string().max(30).alphanum().required().label('First name'),

  //last name must be string within 30 character limit
  lastName: Joi.string().max(30).alphanum().required().label('Last name'),

  // email must require with two minimun domain atoms
  email: Joi.string()
    .email({
      minDomainAtoms: 2
    })
    .required()
    .label('Email'),

  companyName: Joi.string().max(30).label('Company name')
});

// Uptime Site Check
const uptimeSiteCheckSchema = Joi.object().keys({
  //name of site
  name: Joi.string().max(30).required().label('Name'),

  // check interval
  pingInterval: Joi.number().required().label('Check Interval'),

  // Http Method
  httpMethod: Joi.string().valid('GET', 'POST', 'PUT', 'DELETE').label('Http Method'),

  // Http Type
  httpType: Joi.string().valid('http', 'https').label('URL/IP'),

  // URL without protocol
  domain: Joi.string().required().min(1).label('URL/IP'),

  // port
  port: Joi.number().required().min(1).label('Port'),

  // consider down after
  timeout: Joi.number().required().label('Consider site down after'),

  // when down alert after
  alertAfter: Joi.number().required().label('When down alert after'),

  // resend alert every
  alertRepeatInterval: Joi.number().required().label('Resend alert every'),

  // Alert site status to
  userList: Joi.array().label('Alert site status to')
});

/************* Start: Customer List Filter **************/

const userFilterSchema = Joi.object().keys({
  // filters on user property
  filters: Joi.array().items(
    Joi.object().keys({
      propertyName: Joi.string().required().max(512),
      comparisonType: Joi.string().required(),
      propertyType: Joi.string().required(),
      value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean(), Joi.date()).required().allow(null)
    })
  ),

  // event performed filters
  performedFilter: Joi.array().items(
    Joi.object().keys({
      eventName: Joi.string().max(512).required(),
      filters: Joi.array().items(
        Joi.object().keys({
          propertyName: Joi.string().required().max(512),
          comparisonType: Joi.string().required(),
          value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean(), Joi.date()).required().allow(null)
        })
      ),
      duration: Joi.object().keys({
        type: Joi.string().required().max(512),
        number: Joi.object().keys({
          value: Joi.number().allow(null),
          valueRange: Joi.array().optional(),
          valueUnit: Joi.string().valid('minutes', 'hours', 'days', 'months').allow(null),
          comparisonType: Joi.string().valid('>=', '<', 'between').allow(null)
        }),
        date: Joi.object().keys({
          value: Joi.date().allow(null),
          comparisonType: Joi.string().valid('<', '>').required().allow(null)
        }),
        date_range: Joi.object().keys({
          value: Joi.array().items(Joi.date()).allow(null),
          comparisonType: Joi.string().valid('in_between').required().allow(null)
        }),
        hours: Joi.object().required().keys({
          enabled: Joi.boolean().required(),
          start: Joi.number().required(),
          end: Joi.number().required()
        })
      }),
      count: Joi.number().required(),
      countRange: Joi.array().optional(),
      countComparisonType: Joi.string().valid('>=', '<=', '=', '>', '<', 'between').required(),
      matchAnyOfSubFilters: Joi.boolean().optional(),

      // Campaign Response Properties
      campaignRespondedTo: Joi.string().required().allow(''),
      campaignResponseType: Joi.string().required().allow('')
    })
  ),

  // event not performed filters
  notPerformedFilter: Joi.array().items(
    Joi.object().keys({
      eventName: Joi.string().max(512).required(),
      filters: Joi.array().items(
        Joi.object().keys({
          propertyName: Joi.string().required().max(512),
          comparisonType: Joi.string().required(),
          value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean(), Joi.date()).required().allow(null)
        })
      ),
      duration: Joi.object().keys({
        type: Joi.string().required().max(512),
        number: Joi.object().keys({
          value: Joi.number().allow(null),
          valueRange: Joi.array().optional(),
          valueUnit: Joi.string().valid('minutes', 'hours', 'days', 'months').allow(null),
          comparisonType: Joi.string().valid('>=', '<', 'between').allow(null)
        }),
        date: Joi.object().keys({
          value: Joi.date().allow(null),
          comparisonType: Joi.string().valid('<', '>').required().allow(null)
        }),
        date_range: Joi.object().keys({
          value: Joi.array().items(Joi.date()).allow(null),
          comparisonType: Joi.string().valid('in_between').required().allow(null)
        }),
        hours: Joi.object().required().keys({
          enabled: Joi.boolean().required(),
          start: Joi.number().required(),
          end: Joi.number().required()
        })
      }),
      count: Joi.number().valid(0).required(),
      countComparisonType: Joi.string().required(),
      matchAnyOfSubFilters: Joi.boolean().optional(),

      // Campaign Response Properties
      campaignRespondedTo: Joi.string().required().allow(''),
      campaignResponseType: Joi.string().required().allow('')
    })
  ),

  matchAll: Joi.boolean().required()
});

const inActionCampaignEventFilterSchema = Joi.object().keys({
  onAction: Joi.object().keys({
    eventName: Joi.string().max(512).required(),
    filters: Joi.array().items(
      Joi.object().keys({
        propertyName: Joi.string().required().max(512),
        comparisonType: Joi.string().required(),
        value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean(), Joi.date()).required().allow(null)
      })
    ),
    count: Joi.number().required(),
    countComparisonType: Joi.string().valid('>=').required()
  }),

  nonAction: Joi.object().keys({
    eventName: Joi.string().max(512).required(),
    filters: Joi.array().items(
      Joi.object().keys({
        propertyName: Joi.string().required().max(512),
        comparisonType: Joi.string().required(),
        value: Joi.alternatives().try(Joi.string(), Joi.number(), Joi.boolean(), Joi.date()).required().allow(null)
      })
    ),
    duration: Joi.object().keys({
      type: Joi.string().required().max(512),
      number: Joi.object().keys({
        value: Joi.number().allow(null),
        valueUnit: Joi.string().valid('minutes', 'hours', 'days', 'months').allow(null),
        comparisonType: Joi.string().valid('>=', '<').allow(null)
      })
    }),
    count: Joi.number().required(),
    countComparisonType: Joi.string().valid('=').required()
  }),

  nonActionDuration: Joi.number().optional()
});

/************* End: Customer List Filter**************/

const validate = function (data, schema) {
  return Joi.validate(data, schema, {
    abortEarly: false
  });
};

const getColorValidator = function (label) {
  let colorRegex = /^#[0-9a-fA-F]{6}$/;
  return Joi.string()
    .label(label)
    .required()
    .regex(colorRegex)
    .error(() => {
      return {
        message: `${label}: Please provide valid color hex value.`
      };
    });
};

const validateInAppNotificationMessage = function (type, content) {
  const closeButtonSchema = Joi.object().keys({
    visible: Joi.boolean().required(),
    color: getColorValidator('Close Button Color')
  });

  const backgroundSchema = Joi.object().keys({
    isImage: Joi.boolean().required(),
    imageUrl: Joi.string().when('isImage', {
      is: true,
      then: Joi.required()
    }),
    bgColor: getColorValidator('Background Color')
  });

  const textSectionSchema = Joi.object().keys({
    visible: Joi.boolean().required(),
    title: Joi.string().required().allow(''),
    titleColor: getColorValidator('Title color'),
    description: Joi.string().label('Description').required().allow('').max(100),
    descriptionColor: getColorValidator('Description color').required(),
    positionType: Joi.string().required().valid('top', 'center', 'bottom'),
    media: Joi.object()
      .required()
      .keys({
        visible: Joi.boolean().required(),
        type: Joi.string().required().valid('image'),
        url: Joi.string().label('Media Image URL').allow('').when('visible', {
          is: true,
          then: Joi.required()
        })
      })
  });

  const buttonsContainerSchema = Joi.object().keys({
    visible: Joi.boolean().required(),
    buttons: Joi.array().items({
      text: Joi.string().label('Button Text').required(),
      textColor: getColorValidator('Button Text Color'),
      backgroundColor: getColorValidator('Button Background Color'),
      closeOnClick: Joi.boolean().required(),
      iosUrl: Joi.string()
        .required()
        .label('iOS URL')
        .when('closeOnClick', {
          is: true,
          then: Joi.allow('')
        }),
      androidUrl: Joi.string()
        .required()
        .label('Android URL')
        .when('closeOnClick', {
          is: true,
          then: Joi.allow('')
        })
        .label('Android URL'),
      borderRadius: Joi.number().required().min(0).max(100)
    })
  });

  const formDataSchema = Joi.object().optional();

  let coverMessageSchema = Joi.object().keys({
    closeButton: closeButtonSchema,

    background: backgroundSchema,

    text: textSectionSchema,

    buttons: buttonsContainerSchema,

    formData: formDataSchema
  });

  let modalMessageSchema = Joi.object().keys({
    closeButton: closeButtonSchema,

    containerBackground: Joi.object().keys({
      opacity: Joi.number().required().min(0).max(100),
      color: getColorValidator('Popup Overlay color')
    }),

    background: backgroundSchema,

    text: textSectionSchema,

    buttons: buttonsContainerSchema,

    formData: formDataSchema
  });

  let bannerMessageSchema = Joi.object().keys({
    background: backgroundSchema,

    text: textSectionSchema,

    buttons: buttonsContainerSchema,

    formData: formDataSchema
  });

  if (type == 'header' || type == 'footer') {
    return validate(content, bannerMessageSchema);
  } else if (type == 'cover') {
    return validate(content, coverMessageSchema);
  } else if (type == 'modal') {
    return validate(content, modalMessageSchema);
  } else if (type == 'bigModal') {
    return validate(content, modalMessageSchema);
  } else {
    throw new Error(`Invalid in-app message type ${type}`);
  }
};

const validateWebSocialProofMessage = function (type, content) {
  let widgetSchema = Joi.object().keys({
    type: Joi.string().required(),
    typeName: Joi.string().required(),
    header: Joi.string().required().label('Widget Header').max(30),
    userTitle: Joi.string().required().label('Visitor Referral Text').max(20),
    userCount: Joi.number().required(),
    city: Joi.string().optional(),
    activity: Joi.string().required().label('Activity Description').max(30),
    time: Joi.string().required().max(30),
    icon: Joi.string().required().allow(null).allow('')
  });

  let eventSchema = Joi.object()
    .keys({
      name: Joi.string().required().label('Activity Event Name'),
      property: Joi.string().optional().allow(null).allow('').label('Activity Event Identifier'),

      triggerEvent: Joi.string().required().label('Trigger Event'),

      triggerEventProperty: Joi.string().optional().allow(null).allow('').label('Trigger Event Identifier')
    })
    .required();

  let finalSchema = Joi.object().keys({
    widget: widgetSchema,
    event: eventSchema,
    skipOlderThan: Joi.number().required(),
    minActivities: Joi.number().required().label('Minimum Number Of Activities To Show'),
    maxActivities: Joi.number().required().label('Maximum Number Of Activities To Show'),
    themeColor: Joi.string().required()
  });

  return validate(content, finalSchema);
};

export { registerSchema, userProfileSchema, uptimeSiteCheckSchema, userFilterSchema, inActionCampaignEventFilterSchema, validateInAppNotificationMessage, validateWebSocialProofMessage, validate };
